import { type ILevoConfig, LevoClient } from '@levo-so/core';
import { LevoMembership } from '@levo-so/core/membership-plugin';

export const levoClient = new LevoClient({
  workspace: 'WGLBUT82',
  apiUrl: process.env.NEXT_PUBLIC_LEVO_PUBLIC_API_URL,
  NODE_ENV: process.env.NODE_ENV,
  APP_MODE: process.env.NEXT_PUBLIC_APP_MODE as ILevoConfig['APP_MODE'],
});

export const levoMembership = new LevoMembership(levoClient);
