import '@/styles/globals.css';
import '@/styles/progressbar.css';
import 'react-calendar/dist/Calendar.css';
import '@/styles/calendar.css';
import React from 'react';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Script from 'next/script';
import { LevoProvider } from '@levo-so/react';
import { LevoInsightsPage } from '@levo-so/react/audience';

import { theme } from '@/theme';
import { store } from '@/store/index';
import TopProgressBar from '@/components/Progressbar';
import { levoClient, levoMembership } from '@/levo/client';

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => (
  <LevoProvider client={levoClient} membership={levoMembership}>
    <QueryClientProvider client={queryClient}>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <Script
            id="gtag-code"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
        </>
      )}
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <TopProgressBar />
          <LevoInsightsPage />
          <Component {...pageProps} />
        </Provider>
      </ChakraProvider>
    </QueryClientProvider>
  </LevoProvider>
);

export default MyApp;
